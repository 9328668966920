
<template>
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform opacity-0"
    enter-to-class="transform opacity-100"
  >
    <div v-if="done" class="w-full h-full antialiased bg-gray-100">
      <div
        v-bind="shop"
        class="flex flex-row-reverse items-center justify-between w-full py-3 shadow-md bg-primary md:py-4"
      >
        <div class="flex flex-row items-end mr-4 md:mr-8">
          <div
            class="mr-2 text-xl font-black text-center text-white md:text-2xl lg:text-5xl md:mr-4 font-mine"
          >
            لوحة التحكم
          </div>
          <img
            src="@/assets/img/cosinelogowhite.svg"
            class="w-10 h-10 md:h-14 md:w-14 lg:h-16 lg:w-16 place-self-center"
          />
        </div>
        <div class="flex justify-center ml-4 md:hidden">
          <div class="relative">
            <button
              @click="dropdownOpen = !dropdownOpen"
              class="relative z-10 block p-2 bg-white rounded-md focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 icon text-primary icon-tabler icon-tabler-menu-2"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="4" y1="6" x2="20" y2="6" />
                <line x1="4" y1="12" x2="20" y2="12" />
                <line x1="4" y1="18" x2="20" y2="18" />
              </svg>
            </button>

            <div
              v-show="dropdownOpen"
              @click="dropdownOpen = false"
              class="fixed inset-0 z-10 w-full h-full"
            ></div>

            <div
              v-show="dropdownOpen"
              class="absolute z-20 px-4 py-2 mt-2 bg-white rounded-lg shadow-xl"
            >
              <svg
                @click="open"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8 mb-4 text-primary icon icon-tabler icon-tabler-building-store"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="3" y1="21" x2="21" y2="21" />
                <path
                  d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"
                />
                <line x1="5" y1="21" x2="5" y2="10.85" />
                <line x1="19" y1="21" x2="19" y2="10.85" />
                <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
              </svg>
              <svg
                @click="QR()"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8 mb-4 text-primary icon icon-tabler icon-tabler-qrcode"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="4" y="4" width="6" height="6" rx="1" />
                <line x1="7" y1="17" x2="7" y2="17.01" />
                <rect x="14" y="4" width="6" height="6" rx="1" />
                <line x1="7" y1="7" x2="7" y2="7.01" />
                <rect x="4" y="14" width="6" height="6" rx="1" />
                <line x1="17" y1="7" x2="17" y2="7.01" />
                <line x1="14" y1="14" x2="17" y2="14" />
                <line x1="20" y1="14" x2="20" y2="14.01" />
                <line x1="14" y1="14" x2="14" y2="17" />
                <line x1="14" y1="20" x2="17" y2="20" />
                <line x1="17" y1="17" x2="20" y2="17" />
                <line x1="20" y1="17" x2="20" y2="20" />
              </svg>
              <svg
                @click="signOut"
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8 text-primary icon icon-tabler icon-tabler-logout"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
                />
                <path d="M7 12h14l-3 -3m0 6l3 -3" />
              </svg>
            </div>
          </div>
        </div>
        <div class="hidden ml-3 md:flex md:ml-8">
          <svg
            @click="signOut"
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 ml-5 text-white icon icon-tabler icon-tabler-logout"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
            />
            <path d="M7 12h14l-3 -3m0 6l3 -3" /></svg
          ><svg
            @click="QR()"
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 ml-8 text-white icon icon-tabler icon-tabler-qrcode"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="4" y="4" width="6" height="6" rx="1" />
            <line x1="7" y1="17" x2="7" y2="17.01" />
            <rect x="14" y="4" width="6" height="6" rx="1" />
            <line x1="7" y1="7" x2="7" y2="7.01" />
            <rect x="4" y="14" width="6" height="6" rx="1" />
            <line x1="17" y1="7" x2="17" y2="7.01" />
            <line x1="14" y1="14" x2="17" y2="14" />
            <line x1="20" y1="14" x2="20" y2="14.01" />
            <line x1="14" y1="14" x2="14" y2="17" />
            <line x1="14" y1="20" x2="17" y2="20" />
            <line x1="17" y1="17" x2="20" y2="17" />
            <line x1="20" y1="17" x2="20" y2="20" />
          </svg>
          <svg
            @click="open"
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 ml-8 text-white icon icon-tabler icon-tabler-building-store"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="3" y1="21" x2="21" y2="21" />
            <path
              d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"
            />
            <line x1="5" y1="21" x2="5" y2="10.85" />
            <line x1="19" y1="21" x2="19" y2="10.85" />
            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
          </svg>
        </div>
      </div>

      <form @submit.prevent="updateFire(user)">
        <div class="grid grid-cols-1 gap-8 m-4 md:m-8 md:grid-col-2">
          <div>
            <div
              class="flex flex-col col-span-1 overflow-hidden bg-white shadow-md rounded-mine"
            >
              <div
                class="w-full py-3 text-base font-black tracking-normal text-center text-white bg-purple-700 border-b-2 border-gray-200 font-mine"
              >
                بيانات المتجر
              </div>
              <div class="px-4 pt-4 pb-4">
                <div
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                >
                  *صورةالمتجر
                </div>
                <div class="flex justify-center mb-2">
                  <div
                    class="relative w-1/2 mb-2 overflow-hidden rounded-full lg:w-1/3 lg:pb-1/3 pb-1/2"
                  >
                    <img
                      class="absolute top-0 object-cover object-center w-full h-full rounded-mine"
                      :src="shop.image.url"
                    />
                  </div>
                </div>
                <label
                  :class="
                    uploadMessage == 'فشل رفع الصورة'
                      ? 'text-red-500'
                      : 'text-green-500'
                  "
                  class="block px-2 mb-2 text-sm font-extrabold text-center font-mine"
                >
                  {{ uploadMessage }}
                </label>
                <div
                  v-if="progress > 0"
                  class="relative h-3 max-w-xl mb-4 overflow-hidden rounded-full ring-primary ring-2 ring-offset-2"
                >
                  <div class="absolute w-full h-full bg-gray-200"></div>
                  <div
                    class="absolute h-full transition-transform duration-700 ease-in-out bg-primary"
                    :style="{ width: progress + '%' }"
                  ></div>
                </div>
                <div class="flex justify-center mb-4">
                  <label
                    class="flex items-center justify-center w-full px-4 py-2 font-bold tracking-wide text-white uppercase bg-purple-700 border rounded-lg shadow-lg cursor-pointer whitespace-nowrap font-mine"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-8 h-8 icon icon-tabler icon-tabler-cloud-upload"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"
                      />
                      <polyline points="9 15 12 12 15 15" />
                      <line x1="12" y1="12" x2="12" y2="21" />
                    </svg>
                    <span class="ml-2 text-base leading-normal">اختر صورة</span>
                    <input
                      accept="image/*"
                      type="file"
                      class="hidden"
                      @change="uploadImage($event, shop)"
                    />
                  </label>
                </div>
                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="name"
                  >
                    *اسم المتجر
                  </label>
                  <input
                    v-model="shop.name"
                    dir="rtl"
                    class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    id="name"
                    type="text"
                    required
                    oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
                    oninput="setCustomValidity('')"
                  />
                </div>
                <div class="mb-8">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="branch"
                  >
                    اسم الفرع
                  </label>
                  <input
                    v-model="shop.branch"
                    dir="rtl"
                    class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    id="branch"
                    type="text"
                    oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
                    oninput="setCustomValidity('')"
                  />
                </div>

                <div class="flex flex-row-reverse justify-between mb-4">
                  <label
                    class="px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  >
                    منيو مود
                  </label>
                  <button
                    @click="shop.menuMode = !shop.menuMode"
                    type="button"
                    :class="shop.menuMode ? 'bg-primary' : 'bg-gray-200'"
                    class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    aria-pressed="false"
                  >
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span
                      :class="shop.menuMode ? 'translate-x-5' : 'translate-x-0'"
                      class="relative inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow pointer-events-none ring-0"
                    >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span
                        :class="
                          shop.menuMode
                            ? 'opacity-0 ease-out duration-100'
                            : 'opacity-100 ease-in duration-200'
                        "
                        class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity"
                        aria-hidden="true"
                      >
                        <svg
                          class="w-3 h-3 text-gray-400 bg-white"
                          fill="none"
                          viewBox="0 0 12 12"
                        >
                          <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span
                        :class="
                          shop.menuMode
                            ? 'opacity-100 ease-in duration-200'
                            : 'opacity-0 ease-out duration-100'
                        "
                        class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity"
                        aria-hidden="true"
                      >
                        <svg
                          class="w-3 h-3 bg-white text-primary"
                          fill="currentColor"
                          viewBox="0 0 12 12"
                        >
                          <path
                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
                <div class="flex flex-row-reverse justify-between mb-4">
                  <label
                    class="px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  >
                    التوصيل
                  </label>
                  <button
                    @click="shop.delivery = !shop.delivery"
                    type="button"
                    :class="shop.delivery ? 'bg-primary' : 'bg-gray-200'"
                    class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full outline-none cursor-pointer w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                    aria-pressed="false"
                  >
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span
                      :class="shop.delivery ? 'translate-x-5' : 'translate-x-0'"
                      class="relative inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full shadow pointer-events-none ring-0"
                    >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span
                        :class="
                          shop.delivery
                            ? 'opacity-0 ease-out duration-100'
                            : 'opacity-100 ease-in duration-200'
                        "
                        class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity"
                        aria-hidden="true"
                      >
                        <svg
                          class="w-3 h-3 text-gray-400 bg-white"
                          fill="none"
                          viewBox="0 0 12 12"
                        >
                          <path
                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span
                        :class="
                          shop.delivery
                            ? 'opacity-100 ease-in duration-200'
                            : 'opacity-0 ease-out duration-100'
                        "
                        class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity"
                        aria-hidden="true"
                      >
                        <svg
                          class="w-3 h-3 bg-white text-primary"
                          fill="currentColor"
                          viewBox="0 0 12 12"
                        >
                          <path
                            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                          />
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>

                <div id="link" class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="link"
                  >
                    رابط
                  </label>
                  <div class="flex">
                    <span
                      class="inline-flex items-center px-3 text-sm text-white shadow-inner rounded-l-md bg-primary"
                    >
                      cosine.store/
                    </span>
                    <input
                      v-model="shop.link"
                      class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      id="link"
                      type="text"
                      required
                      oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
                      oninput="setCustomValidity('')"
                      pattern="[a-z0-9ـ]+"
                    />
                  </div>
                  <div
                    class="p-2 text-sm font-bold tracking-normal text-center text-red-700 font-mine"
                    v-if="errorLink"
                  >
                    {{ errorLink }}
                  </div>
                </div>

                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="instagram"
                  >
                    انستغرام
                  </label>
                  <div class="flex">
                    <span
                      class="inline-flex items-center px-3 text-sm text-white rounded-l shadow-inner bg-primary"
                    >
                      instagram.com/
                    </span>
                    <input
                      v-model="shop.social.instagram"
                      class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      id="instagram"
                      type="text"
                    />
                  </div>
                </div>
                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="snapchat"
                  >
                    سناب شات
                  </label>
                  <div class="flex">
                    <span
                      class="inline-flex items-center px-3 text-sm text-white rounded-l shadow-inner bg-primary"
                    >
                      snapchat.com/add/
                    </span>
                    <input
                      v-model="shop.social.snapchat"
                      class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      id="snapchat"
                      type="text"
                    />
                  </div>
                </div>
                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="whatsapp"
                  >
                    واتساب
                  </label>
                  <div class="flex">
                    <span
                      class="inline-flex items-center px-3 text-sm text-white rounded-l shadow-inner bg-primary"
                    >
                      966
                    </span>
                    <input
                      v-model="shop.social.whatsapp"
                      class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      id="whatsapp"
                      type="tel"
                      placeholder="50000000"
                      pattern="[0-9]+"
                      minlength="9"
                      maxlength="9"
                      oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
                      oninput="setCustomValidity('')"
                    />
                  </div>
                </div>
                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                    for="location"
                  >
                    لوكايشن
                  </label>
                  <div class="flex">
                    <input
                      v-model="shop.social.location"
                      class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      id="location"
                      type="url"
                    />
                  </div>
                </div>
                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  >
                    *الثيم
                  </label>
                  <div class="grid grid-cols-12">
                    <div
                      :class="shop.theme"
                      class="col-span-1 col-start-2 bg-primary rounded-l-md"
                    ></div>
                    <div
                      :class="shop.theme"
                      class="col-span-1 bg-secondary rounded-r-md"
                    ></div>
                    <select
                      v-model="shop.theme"
                      dir="rtl"
                      class="col-span-8 col-start-5 text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    >
                      <option
                        v-for="theme in themes"
                        v-bind:key="theme.value"
                        :value="theme.value"
                      >
                        {{ theme.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              class="flex flex-col col-span-1 overflow-hidden bg-white shadow-md rounded-mine"
            >
              <div
                class="w-full py-3 mb-4 text-base font-black tracking-normal text-center text-white bg-purple-700 font-mine"
              >
                بيانات الأصناف
              </div>

              <div
                v-for="cat in shop.categories"
                v-bind:key="cat.id"
                class="px-4"
              >
                <div class="mb-4">
                  <label
                    class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                  >
                    *اسم الصنف
                  </label>
                  <div class="flex flex-row-reverse justify-between">
                    <input
                      v-model="cat.value"
                      dir="rtl"
                      class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                      type="text"
                      required
                      oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
                      oninput="setCustomValidity('')"
                    /><button
                      @click="prepareRemoveCategory(cat)"
                      class="mr-3 text-base font-black tracking-normal text-center text-red-700"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 icon icon-tabler icon-tabler-trash"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="4" y1="7" x2="20" y2="7" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                        <path
                          d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                        />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div
                @click="addCategory"
                class="p-2 text-base font-bold tracking-normal text-center text-white bg-primary font-mine"
              >
                + أضف صنف
              </div>
            </div>
          </div>
          <div
            class="flex flex-col col-span-1 mb-16 overflow-hidden bg-white shadow-md md:col-span-2 rounded-mine"
          >
            <div
              class="w-full py-3 mb-4 text-base font-black tracking-normal text-center text-white bg-purple-700 border-b-2 border-gray-200 font-mine"
            >
              بيانات المنتجات
            </div>
            <div class="grid grid-cols-1">
              <div
                v-for="(prod, index) in shop.products"
                v-bind:key="prod.id"
                class="px-4 mb-4 md:mb-8"
              >
                <div
                  class="flex flex-col md:flex-row-reverse md:justify-between"
                >
                  <div class="flex items-center justify-end">
                    <p
                      class="ml-1 overflow-hidden text-xl font-black text-center text-gray-900 whitespace-normal font-mine"
                    >
                      {{ prod.name }}
                    </p>
                    <div class="flex-shrink-0 w-10 h-10 mx-2">
                      <img
                        @click="test1(index)"
                        class="w-full h-full rounded-full"
                        :src="prod.image.url"
                      />
                    </div>
                  </div>
                  <div class="flex justify-evenly">
                    <button
                      @click="removeProduct(prod)"
                      class="text-base font-black tracking-normal text-center text-red-700 md:mr-4 font-mine"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 icon icon-tabler icon-tabler-trash"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <line x1="4" y1="7" x2="20" y2="7" />
                        <line x1="10" y1="11" x2="10" y2="17" />
                        <line x1="14" y1="11" x2="14" y2="17" />
                        <path
                          d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                        />
                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                      </svg>
                    </button>
                    <button
                      @click="enterEdit(index)"
                      class="text-base font-black tracking-normal text-center md:mr-4 text-primary font-mine"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 icon icon-tabler icon-tabler-edit"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"
                        />
                        <path
                          d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"
                        />
                        <line x1="16" y1="5" x2="19" y2="8" />
                      </svg>
                    </button>
                    <button
                      @click="swapDown(index)"
                      class="text-base font-black tracking-normal text-center text-primary md:mr-4 font-mine"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 icon icon-tabler icon-tabler-chevron-down"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 9 12 15 18 9" />
                      </svg>
                    </button>
                    <button
                      @click="swapUp(index)"
                      class="text-base font-black tracking-normal text-center text-primary md:mr-4 font-mine"
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-8 icon icon-tabler icon-tabler-chevron-up"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <polyline points="6 15 12 9 18 15" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              @click="addProduct"
              class="p-2 text-base font-bold tracking-normal text-center text-white bg-primary font-mine"
            >
              + أضف منتج
            </div>
          </div>
        </div>
        <button
          class="fixed bottom-0 flex items-end justify-center w-full py-2 text-base font-bold text-white bg-purple-700 md:py-3 focus:bg-purple-800 focus:outline-none font-mine"
          type="submit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 mr-2 icon icon-tabler icon-tabler-device-floppy"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
              d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"
            />
            <circle cx="12" cy="14" r="2" />
            <polyline points="14 4 14 8 8 8 8 4" />
          </svg>
          <span class="font-black">حفظ</span>
        </button>
      </form>
    </div>
    <div
      v-else
      class="fixed flex flex-col items-center justify-center w-screen h-screen bg-white z-90"
    >
      <img
        src="@/assets/img/cosinelogo.svg"
        class="w-32 h-32 md:h-40 md:w-40 lg:h-48 lg:w-48 animate-bounce"
      />
      <div class="text-3xl font-black md:text-4xl lg:text-5xl text-primary">
        Cosine Shops
      </div>
    </div>
  </transition>
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform translate-x-full "
    enter-to-class="transform translate-x-0 "
    leave-active-class="transition duration-300 ease-in"
    leave-from-class="transform translate-x-0 "
    leave-to-class="transform translate-x-full"
  >
    <QR
      v-if="qrModalState"
      :name="shop.name"
      :branch="shop.branch"
      :image="shop.image.url"
      :link="shop.link"
      :theme="shop.theme"
      @hide="QR()"
    ></QR
  ></transition>

  <transition
    enter-active-class="transition duration-300 ease-in-out"
    enter-from-class="transform scale-95 opacity-0"
    enter-to-class="transform scale-100 opacity-100"
    leave-active-class="transition duration-200 ease-in-out"
    leave-from-class="transform scale-100 opacity-100"
    leave-to-class="transform scale-95 opacity-0"
  >
    <CModal
      title="تأكيد الحذف"
      body="حذف الصنف يؤدي إلى حذف جميع منتجاته"
      accept="حذف"
      @order="removeCategory(categoryRemoved)"
      @hide="categoryModalState = false"
      v-if="categoryModalState"
    ></CModal
  ></transition>
  <div
    v-if="saveModalState"
    class="fixed inset-0 flex flex-col items-center justify-center transition-opacity"
  >
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    <img
      src="@/assets/img/cosinelogo.svg"
      class="w-32 h-32 md:h-40 md:w-40 lg:h-48 lg:w-48 animate-spin"
    />
    <div class="z-50 text-3xl font-black text-white md:text-4xl lg:text-5xl">
      Cosine Shops
    </div>
  </div>
  <!-- edit -->
  <transition
    enter-active-class="transition duration-300 ease-in"
    enter-from-class="transform translate-y-full "
    enter-to-class="transform translate-y-0 "
    leave-active-class="transition duration-300 ease-in"
    leave-from-class="transform translate-y-0 "
    leave-to-class="transform translate-y-full"
  >
    <div
      v-if="editState"
      class="fixed inset-0 z-10 w-full overflow-y-auto bg-white"
    >
      <div class="relative py-3 shadow-md md:py-4 bg-primary">
        <h1 class="text-xl font-black text-center text-white font-mine">
          تعديل المنتج
        </h1>
      </div>
      <div class="mx-2 my-4 md:mx-6">
        <div
          class="relative mb-4 overflow-hidden rounded-lg md:mx-auto pb-1/1 md:w-1/2 md:pb-1/2"
        >
          <img
            class="absolute top-0 object-cover object-center w-full h-full rounded-mine"
            :src="prod.image.url"
          />
        </div>
        <label
          :class="
            uploadMessage == 'فشل رفع الصورة'
              ? 'text-red-500'
              : 'text-green-500'
          "
          class="block px-2 mb-2 text-sm font-extrabold text-center font-mine"
        >
          {{ uploadMessage }}
        </label>
        <div
          v-if="progress > 0"
          class="relative h-3 max-w-xl mb-4 overflow-hidden rounded-full ring-primary ring-2 ring-offset-2"
        >
          <div class="absolute w-full h-full bg-gray-200"></div>
          <div
            class="absolute h-full transition-transform duration-700 ease-in-out bg-primary"
            :style="{ width: progress + '%' }"
          ></div>
        </div>

        <div class="flex justify-center mb-4">
          <label
            class="flex items-center justify-center w-full px-4 py-2 font-bold tracking-wide text-white uppercase bg-purple-700 border rounded-lg shadow-lg cursor-pointer whitespace-nowrap font-mine"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-8 h-8 icon icon-tabler icon-tabler-cloud-upload"
              viewBox="0 0 24 24"
              stroke-width="2"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"
              />
              <polyline points="9 15 12 12 15 15" />
              <line x1="12" y1="12" x2="12" y2="21" />
            </svg>
            <span class="ml-2 text-base leading-normal">اختر صورة</span>
            <input
              accept="image/*"
              type="file"
              class="hidden"
              @change="uploadImage($event, prod)"
            />
          </label>
        </div>
        <div class="mb-4">
          <label
            class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
          >
            *اسم المنتج
          </label>
          <input
            v-model="prod.name"
            dir="rtl"
            class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            type="text"
            required
            oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
            oninput="setCustomValidity('')"
          />
        </div>
        <div class="mb-4">
          <label
            class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
          >
            وصف المنتج
          </label>
          <textarea
            v-model="prod.description"
            dir="rtl"
            class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          />
        </div>
        <div class="mb-4">
          <label
            class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
          >
            *حالة المنتج
          </label>

          <select
            v-model="prod.status"
            dir="rtl"
            class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
          >
            <option>متوفر</option>

            <option>غير متوفر</option>
            <option>مخفي</option>
          </select>
        </div>
        <div class="flex justify-between">
          <div class="w-3/5 mb-4 mr-2">
            <label
              class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
            >
              *الصنف
            </label>

            <select
              v-model="prod.category"
              dir="rtl"
              class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            >
              <option v-for="n in shop.categories" v-bind:key="n.id" :value="n">
                {{ n.value }}
              </option>
            </select>
          </div>
          <div class="w-2/5 mb-4">
            <label
              class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
            >
              *سعر المنتج
            </label>
            <div class="flex">
              <span
                class="inline-flex items-center px-3 text-sm font-bold text-white shadow-inner font-mine rounded-l-md bg-primary"
              >
                ريال
              </span>
              <input
                v-model="prod.price"
                class="block w-full text-base text-center transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                type="number"
                required
                oninvalid="this.setCustomValidity('يجب تعبئة الحقل بشكل صحيح')"
                oninput="setCustomValidity('')"
              />
            </div>
          </div>
        </div>
        <div v-if="prod.variants">
          <div class="mb-2">
            <label
              class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
            >
              اسم المتغير
            </label>
            <input
              v-model="prod.variantTitle"
              dir="rtl"
              class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
              type="text"
            />
          </div>
          <p class="mb-2 text-right font-mine">
            سعر الخيار هو إضافة إلى سعر المنتج الرئيسي
          </p>
          <div
            class="mb-4"
            v-for="option in prod.variants"
            v-bind:key="option.id"
          >
            <div class="grid grid-cols-9 gap-x-4">
              <button
                @click="removeVariant(option.id, prod)"
                class="text-center text-red-700 mt-7"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-8 h-8 col-span-1 icon icon-tabler icon-tabler-trash"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1="4" y1="7" x2="20" y2="7" />
                  <line x1="10" y1="11" x2="10" y2="17" />
                  <line x1="14" y1="11" x2="14" y2="17" />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
              </button>
              <div class="col-span-4">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                >
                  سعر الخيار
                </label>
                <div class="flex">
                  <span
                    class="inline-flex items-center px-3 text-sm font-bold text-white shadow-inner font-mine rounded-l-md bg-primary"
                  >
                    ريال
                  </span>
                  <input
                    v-model="option.price"
                    class="block w-full text-base text-center transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    type="number"
                  />
                </div>
              </div>
              <div class="col-span-4">
                <label
                  class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
                >
                  اسم الخيار
                </label>
                <input
                  v-model="option.name"
                  dir="rtl"
                  class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div
            @click="addVariant(prod)"
            class="mb-4 text-base font-bold tracking-normal text-center text-primary font-mine"
          >
            + أضف خيار
          </div>
          <div
            @click="removeVariants(prod)"
            class="mb-4 text-base font-bold tracking-normal text-center text-red-700 font-mine"
          >
            X احذف المتغير
          </div>
        </div>
        <div
          v-else
          @click="addVariants(prod)"
          class="mb-4 text-base font-bold tracking-normal text-center text-primary font-mine"
        >
          + أضف متغير
        </div>
        <div class="mb-4" v-for="addOn in prod.addOns" v-bind:key="addOn.id">
          <div class="grid grid-cols-9 gap-x-4">
            <button
              @click="removeAddOn(addOn.id, prod)"
              class="text-red-700 mt-7"
              type="button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-8 h-8 col-span-1 icon icon-tabler icon-tabler-trash"
                viewBox="0 0 24 24"
                stroke-width="2"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="4" y1="7" x2="20" y2="7" />
                <line x1="10" y1="11" x2="10" y2="17" />
                <line x1="14" y1="11" x2="14" y2="17" />
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
              </svg>
            </button>
            <div class="col-span-4">
              <label
                class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
              >
                سعر الإضافة
              </label>
              <div class="flex">
                <span
                  class="inline-flex items-center px-3 text-sm font-bold text-white shadow-inner font-mine rounded-l-md bg-primary"
                >
                  ريال
                </span>
                <input
                  v-model="addOn.price"
                  class="block w-full text-base text-center transition-all duration-500 ease-in-out border-gray-300 outline-none rounded-r-md font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                  type="number"
                />
              </div>
            </div>
            <div class="col-span-4">
              <label
                class="block px-2 mb-2 text-sm font-extrabold text-right text-grey-darker font-mine"
              >
                اسم الإضافة
              </label>
              <input
                v-model="addOn.name"
                dir="rtl"
                class="block w-full text-base transition-all duration-500 ease-in-out border-gray-300 rounded-md outline-none font-mine focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                type="text"
              />
            </div>
          </div>
        </div>
        <div
          @click="addAddOn(prod)"
          class="mb-4 text-base font-bold tracking-normal text-center text-primary font-mine"
        >
          + أضف إضافة
        </div>
        <button
          v-if="!uploadstate"
          @click="exitEdit"
          class="flex items-center justify-center w-full px-4 py-2 mb-4 font-bold tracking-wide uppercase bg-white border rounded-lg shadow-lg cursor-pointer whitespace-nowrap text-primary border-primary font-mine"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 icon icon-tabler icon-tabler-arrow-narrow-left"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="5" y1="12" x2="19" y2="12" />
            <line x1="5" y1="12" x2="9" y2="16" />
            <line x1="5" y1="12" x2="9" y2="8" />
          </svg>
          <span class="ml-2 text-base leading-normal"> عودة</span>
        </button>
        <button
          v-else
          class="flex items-center justify-center w-full px-4 py-2 mb-4 font-bold tracking-wide uppercase bg-white border rounded-lg shadow-lg cursor-pointer whitespace-nowrap text-primary border-primary font-mine"
        >
          <span class="ml-2 text-base leading-normal animate-bounce">
            جاري رفع الصورة</span
          >
        </button>
      </div>
    </div>
  </transition>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { db } from "/Users/alialeidmax/Developer/cosine/firebaseConfig.js";
import { auth } from "/Users/alialeidmax/Developer/cosine/firebaseConfig.js";
import { storage } from "/Users/alialeidmax/Developer/cosine/firebaseConfig.js";
import CModal from "@/components/confirmation_modal";
const QR = defineAsyncComponent(() => import("@/components/qr.vue"));

export default {
  created: function () {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.readShops(this.user);
        this.done = true;
      } else {
        this.user = null;
        this.$router.replace({ name: "Login" });
      }
    });
  },
  components: { CModal, QR },
  name: "App",
  data() {
    return {
      prod: {},
      editIndex: null,
      editState: false,
      uploadstate: false,
      uploadMessage: "",
      progress: 0,
      shop: {},
      themes: [
        { name: "الأساسي", value: "" },
        { name: "الرمادي", value: "grayTheme" },
        { name: "الأزرق", value: "blueTheme" },
        { name: "الأخضر", value: "greenTheme" },
        { name: "البرتقالي", value: "pumpkinTheme" },
        { name: "الذهبي", value: "goldTheme" },
        { name: "الوردي", value: "pinkTheme" },
        { name: "الأحمر", value: "redTheme" },
        { name: "الأخضر الفاتح", value: "lightGreenTheme" },
        { name: "بطيخي", value: "melonTheme" },
      ],
      errorLink: null,
      oldLink: null,
      user: null,
      done: false,
      categoryModalState: false,
      saveModalState: false,
      qrModalState: false,
      dropdownOpen: false,
      categoryRemoved: null,
    };
  },
  computed: {},
  methods: {
    enterEdit(x) {
      this.editIndex = x;
      this.prod = this.shop.products[x];
      this.editState = true;
      this.uploadMessage = "";
      this.progress = 0;
    },
    exitEdit() {
      if (!this.uploadstate) {
        this.shop.products.splice(this.editIndex, 1, this.prod);
        this.editState = false;
        this.uploadMessage = "";
        this.progress = 0;
      }
    },
    swapUp(index) {
      if (index !== 0) {
        let temp = this.shop.products[index];
        this.shop.products[index] = this.shop.products[index - 1];
        this.shop.products[index - 1] = temp;
      }
    },
    swapDown(index) {
      if (index !== this.shop.products.length - 1) {
        let temp = this.shop.products[index];
        this.shop.products[index] = this.shop.products[index + 1];
        this.shop.products[index + 1] = temp;
      }
    },
    async readShops(user) {
      this.shop = {};
      await db
        .collection("shops")
        .doc(user.uid)
        .get()
        .then((doc) => {
          this.shop = doc.data();
          this.prod = this.shop.products[0];
          this.oldLink = this.shop.link;
        })
        .catch(() => {});
    },
    uploadImage(event, prod) {
      this.uploadstate = true;
      if (event.target.files[0]) {
        let file = event.target.files[0];
        let date = Date.now();
        let fileName = date;
        var storageRef = storage.ref(
          "stores_media/" + this.user.uid + "/" + fileName
        );

        let uploadTask = storageRef.put(file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            this.progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100 - 10;
            console.log("Upload is " + this.progress + "% done");
          },
          () => {
            this.uploadMessage = "فشل رفع الصورة";
            this.uploadstate = false;
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              if (prod.image.path != "") {
                var desertRef = storage.ref(prod.image.path);

                // Delete the file
                desertRef.delete().then(function () {
                  // File deleted successfully
                });
              }
              let newUrl;
              newUrl = downloadURL.replace(date, `${date}_400x400`);
              newUrl = newUrl.replace(
                "https://firebasestorage.googleapis.com/v0/b/image.cosine.store/o",
                "https://image.cosine.store"
              );
              setTimeout(() => {
                prod.image.url = newUrl;
                prod.image.path = storageRef.fullPath + "_400x400";
                this.uploadstate = false;
                this.progress += 10;
                this.uploadMessage = "تم رفع الصورة بنجاح";
              }, 3000);
            });
          }
        );
      }
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    // Add item if not already in the cart
    addProduct() {
      this.shop.products.push({
        id: this.getRndInteger(1, 99999999),
        name: "منتج",
        description: "",
        category: this.shop.categories[0],
        price: 0.0,
        image: { path: "", url: "" },
        addOns: [],
        status: "متوفر",
      });
    },
    removeProduct(prod) {
      if (prod.image.path.length > 0) {
        var desertRef = storage.ref(prod.image.path);

        // Delete the file
        desertRef.delete().then(function () {
          // File deleted successfully
        });
      }
      for (let i = 0; i < this.shop.products.length; i++) {
        if (this.shop.products[i].id === prod.id) {
          this.shop.products.splice(i, 1);
          break;
        }
      }
    },
    addAddOn(prod) {
      prod.addOns.push({
        id: this.getRndInteger(1, 9999),
        name: "إضافة",
        price: 0.0,
      });
    },
    removeAddOn(id, prod) {
      for (let i = 0; i < prod.addOns.length; i++) {
        if (prod.addOns[i].id === id) {
          prod.addOns.splice(i, 1);
          break;
        }
      }
    },
    addVariants(prod) {
      prod.variantTitle = "متغير";
      prod.variants = [
        {
          id: this.getRndInteger(1, 99999999),
          name: "خيار",
          price: 0.0,
        },
        {
          id: this.getRndInteger(1, 99999999),
          name: "خيار",
          price: 0.0,
        },
      ];
    },
    removeVariants(prod) {
      delete prod.variantTitle;
      delete prod.variants;
    },
    addVariant(prod) {
      prod.variants.push({
        id: this.getRndInteger(1, 99999999),
        name: "خيار",
        price: 0.0,
      });
    },
    removeVariant(id, prod) {
      for (let i = 0; i < prod.variants.length; i++) {
        if (prod.variants[i].id === id) {
          prod.variants.splice(i, 1);
          break;
        }
      }
      if (prod.variants.length < 2) {
        this.removeVariants(prod);
      }
    },
    addCategory() {
      this.shop.categories.push({
        id: this.getRndInteger(2, 9999999),
        value: "",
      });
    },
    removeCategory(cat) {
      for (let i = 0; i < this.shop.products.length; i++) {
        if (this.shop.products[i].category.id === cat.id) {
          this.shop.products.splice(i, 1);
        }
      }
      for (let i = 0; i < this.shop.categories.length; i++) {
        if (this.shop.categories[i].id === cat.id) {
          this.shop.categories.splice(i, 1);
          break;
        }
      }
      this.categoryModalState = false;
    },
    prepareRemoveCategory(cat) {
      if (this.shop.categories.length > 1) {
        this.categoryRemoved = cat;
        this.categoryModalState = true;
      }
    },
    async updateFire(user) {
      var batch = db.batch();
      batch.set(db.collection("links").doc(this.shop.link), {
        id: this.user.uid,
      });
      batch.delete(db.collection("links").doc(this.oldLink));
      batch.set(db.collection("shops").doc(user.uid), this.shop);
      var doIt = false;
      if (this.oldLink != this.shop.link) {
        await db
          .collection("links")
          .doc(this.shop.link)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.errorLink = "الرابط موجود بالفعل اختر رابط مختلف";
              document.getElementById("link").scrollIntoView({
                block: "center",
                inline: "nearest",
              });
            } else {
              this.saveModalState = true;
              this.errorLink = null;
              doIt = true;
            }
          });
        if (doIt) {
          await batch
            .commit()
            .then(() => {
              this.oldLink = this.shop.link;
            })
            .catch(() => {});
        }
      } else {
        this.saveModalState = true;
        this.errorLink = null;
        await db
          .collection("shops")
          .doc(user.uid)
          .set(this.shop)
          .then()

          .catch(() => {});
      }

      this.saveModalState = false;
    },
    open() {
      var url = "https://cosine.store/" + this.shop.link;
      window.open(url, "_blank");
    },
    QR() {
      this.qrModalState = !this.qrModalState;
    },

    signOut() {
      auth.signOut();
    },
  },
};
</script>

